import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { validators } from '@/shared/form/validators';

export default class RelationToOneField extends GenericField {
    constructor(
        name,
        label,
        fetchFn,
        mapperFn,
        { required = false, autocompleteRequired = false, enabled = true, firstLetterFilter = false } = {},
        filterParams = {
            textField: 'text',
            valueField: 'value',
            dynamic: false,
            type: 'select',
            searchByText: false,
            width: 'sm',
        },
        parentFields = [], // or children
        otherParams = {},
    ) {
        super(name, label, filterParams);
        this.required = required;
        this.autocompleteRequired = autocompleteRequired;
        this.firstLetterFilter = firstLetterFilter;
        this.enabledValidation = enabled;
        this.fetchFn = fetchFn;
        this.mapperFn = mapperFn;
        this.textField = filterParams.textField;
        this.valueField = filterParams.valueField;
        this.dynamic = filterParams.dynamic;
        this.type = filterParams?.type || 'select';
        this.width = filterParams?.width;
        this.options = [];
        this.fetchParams = {};
        // TODO: Remove when all are converted to async
        this.children = parentFields;
        this.parentFields = parentFields;
        this.searchByText = filterParams.searchByText;
        this.asyncData = otherParams?.asyncData;
        this.hasPagination = otherParams?.hasPagination;
        if (!this.asyncData) {
            this.getOptions();
        }
        this.pagination = {};
    }

    getOptions() {
        return new Promise((resolve, reject) => {
            this.fetchFn(this.fetchParams)
                .then((responseData) => {
                    let data = [];
                    if (this.hasPagination) {
                        const { pagination } = responseData;
                        this.pagination = pagination;
                        const formattedResponseData = responseData.data.map(this.mapperFn);
                        this.options.push(...formattedResponseData);
                    } else {
                        data = responseData?.data && responseData?.data.constructor === Array ? responseData.data : responseData;
                        this.options = data.map(this.mapperFn);
                    }
                    resolve(this.options);
                }).catch(() => {
                    reject();
                });
        });
    }

    async updateOptions(empty) {
        if (empty) {
            this.options = [];
            this.fetchParams = {};
        } else {
            await this.getOptions();
        }
    }

    forPresenter(value) {
        return this.options.find((el) => el.value === value)?.text;
    }

    forFormRules() {
        let output = {};

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }
        if (this.autocompleteRequired) {
            output = {
                ...output,
                ...validators.autocompleteRequired(this.enabledValidation),
            };
        }

        return output;
    }

    forFilterRules() {
        return undefined;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFilterInitialValue(value) {
        return value;
    }

    forFormCast() {
        let yupChain = yup
            .mixed()
            .nullable(true)
            .label(this.label);

        return yupChain;
    }

    forFilterCast() {
        return yup
            .mixed()
            .label(this.label)
            .transform((value, originalValue) => {
                if (!originalValue) {
                    return null;
                }

                return originalValue.id;
            });
    }
}
